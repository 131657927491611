import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import yup from "../../common/validator";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "../../store/loadingSlice";
import { getErrorMessage } from "../../utils";
import userService from "../../services/user";
import brandService from "../../services/brand";
import saleBankSlipService from "../../services/saleBankSlip";
import companyService from "../../services/company";
import NumberFormat from "react-number-format";
import TabPanel from "../../components/tabPanel";

const validation = {
  cnpj_cpf: yup
    .string()
    .required()
    .transform((value) => value.replace(/[^\d]/g, "")),
  corporateName_firstName: yup.string().trim().required(),
  fantasyName_lastName: yup.string().trim().required(),
  ie_rg: yup
    .string()
    .required()
    .transform((value) => value.replace(/[^\d]/g, "")),
  email: yup.string().trim().email().required(),
  website: yup.string().trim().nullable(),
  active: yup.boolean(),
  phone1: yup.string().transform((value) => value.replace(/[^\d]/g, "")),
  phone2: yup.string().transform((value) => value.replace(/[^\d]/g, "")),
  brands: yup.array(),
  street: yup.string().trim().required(),
  number: yup
    .string()
    .nullable()
    .transform((value) => value.replace(/[^\d]/g, "")),
  district: yup.string().trim().required(),
  zipCode: yup
    .string()
    .required()
    .transform((value) => value.replace(/[^\d]/g, ""))
    .length(8),
  city: yup.string().trim().required(),
  state: yup.string().trim().required().uppercase().length(2),
  complement: yup.string().trim().nullable(),
  company: yup.object({ id: yup.number(), name: yup.string() }).nullable(),
  saleBankSlip: yup.object().nullable(),
  credit: yup.number().required(),
};

const UserPJFormView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [schema, setSchema] = useState(yup.object().shape(validation));
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valueTab, setValueTab] = useState(0);
  const cep = watch("zipCode");
  const [companies, setCompanies] = useState([]);
  const [saleBankSlip, setSaleBankSlip] = useState([]);

  const getData = useCallback(async () => {
    try {
      dispatch(startLoading());
      if (id) {
        const [user, brands, companies, sale] = await Promise.all([
          userService.get(+id),
          brandService.getAll(),
          companyService.getAll(),
          saleBankSlipService.getAll(),
        ]);
        reset(user.data);
        setBrands(brands.data);
        setCompanies(companies.data);
        setSaleBankSlip(sale.data);
      } else {
        const [brands, companies, sale] = await Promise.all([
          brandService.getAll(),
          companyService.getAll(),
          saleBankSlipService.getAll(),
        ]);
        setBrands(brands.data);
        setCompanies(companies.data);
        setSaleBankSlip(sale.data);
      }
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      navigate("..", { replace: true });
    } finally {
      dispatch(endLoading());
    }
  }, [dispatch, enqueueSnackbar, id, navigate, reset]);

  useEffect(() => {
    if (id) {
      setSchema(yup.object().shape(validation));
    } else {
      setSchema(
        yup
          .object()
          .shape({ password: yup.string().trim().required(), ...validation })
      );
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      if (id) {
        await userService.update(data);
      } else {
        await userService.create({ ...data, isPJ: true });
      }
      enqueueSnackbar(`${id ? "Edição" : "Cadastro"} realizado com sucesso`, {
        variant: "success",
      });
      navigate("..");
    } catch (err) {
      enqueueSnackbar(getErrorMessage(err), { variant: "error" });
    } finally {
      dispatch(endLoading());
    }
  };

  const onError = (error) => {
    if (
      error.cnpj_cpf ||
      error.corporateName_firstName ||
      error.fantasyName_lastName ||
      error.ie_rg ||
      error.website ||
      error.active ||
      error.phone1 ||
      error.phone2 ||
      error.brands ||
      error.companies ||
      error.saleBankSlip
    ) {
      setValueTab(0);
    } else if (error.credit) {
      setValueTab(2);
    } else {
      setValueTab(1);
    }
  };

  const searchCep = useCallback(
    async (value) => {
      try {
        dispatch(startLoading());
        const { data } = await userService.searchZipCode(value);
        if (!data.erro) {
          setValue("street", data.logradouro);
          setValue("district", data.bairro);
          setValue("city", data.localidade);
          setValue("state", data.uf);
        }
      } catch (err) {
        enqueueSnackbar(getErrorMessage(err), { variant: "error" });
      } finally {
        dispatch(endLoading());
      }
    },
    [dispatch, enqueueSnackbar, setValue]
  );

  useEffect(() => {
    if (cep) {
      const value = cep.replace(/[^\d]/g, "");
      if (value.length === 8) searchCep(value);
    }
  }, [cep, searchCep]);

  const tabToggle = (event, newValue) => {
    setValueTab(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  return (
    <>
      <Box
        position="static"
        bgcolor="white"
        sx={{
          borderRadius: "4px 4px 0 0",
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        <Tabs value={valueTab} onChange={tabToggle} variant="scrollable">
          <Tab label="Dados" {...a11yProps(0)} />
          <Tab label="Endereço" {...a11yProps(1)} />
          <Tab label="Crédito" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <TabPanel value={valueTab} index={0}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="corporateName_firstName"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Razão Social"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="fantasyName_lastName"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Nome Fantasia"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="cnpj_cpf"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      format="##.###.###/####-##"
                      customInput={TextField}
                      label="CNPJ"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="ie_rg"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Inscrição Estadual"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="E-mail"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Senha"
                      type="password"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="website"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Website"
                      placeholder="https://"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="phone1"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      format="(##) #####-####"
                      label="Telefone 1"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="phone2"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      format="(##) #####-####"
                      label="Telefone 2"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="company"
                  defaultValue={null}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      {...rest}
                      options={companies}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      onChange={(event, value) => onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          label="Empresa Favorita"
                          error={!!error}
                          helperText={!!error && "O campo é obrigatório."}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="saleBankSlip"
                  defaultValue={null}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      {...rest}
                      options={saleBankSlip}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      onChange={(event, value) => onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          label="Tipo de Venda"
                          error={!!error}
                          helperText={!!error && "O campo é obrigatório."}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="brands"
                  defaultValue={[]}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      {...rest}
                      options={brands}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      fullWidth
                      multiple
                      onChange={(event, value) => onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          label="Fornecedores Permitidos"
                          error={!!error}
                          helperText={!!error && "O campo é obrigatório."}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="active"
                  control={control}
                  defaultValue
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Ativo"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </TabPanel>

        <TabPanel value={valueTab} index={1}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="zipCode"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      format="#####-###"
                      customInput={TextField}
                      label="CEP"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="street"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Rua"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="number"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Número"
                      fullWidth
                      error={!!error}
                      helperText={"Se não possui deixe o campo em branco."}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="district"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Bairro"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="city"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Cidade"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="state"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="UF"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="complement"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Complemento"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </TabPanel>

        <TabPanel value={valueTab} index={2}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="credit"
                  defaultValue={0}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumberFormat
                      customInput={TextField}
                      label="Crédito"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      onValueChange={(value) => onChange(value.floatValue)}
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="availableCredit"
                  defaultValue={0}
                  render={({
                    field: { onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <NumberFormat
                      disabled
                      customInput={TextField}
                      label="Crédito disponível"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      onValueChange={(value) => onChange(value.floatValue)}
                      prefix="R$ "
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      {...rest}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button component={Link} to=".." color="error">
              Cancelar
            </Button>
            <Button type="submit">Salvar</Button>
          </CardActions>
        </TabPanel>
      </form>
    </>
  );
};

export default UserPJFormView;
